<template>
  <div class="md:min-h-screen flex md:py-8 items-center">
    <div class="max-w-6xl mx-auto">
      <div class="grid grid-cols-12 gap-6 items-center">
        <div class="col-span-12 md:col-span-6">
          <div class="h-52 md:h-full overflow-hidden">
            <img class="object-cover md:rounded-xl" src="https://owayy.imgix.net/product/567/346f6245-2569-4f23-964c-c46b90267a2d.png?fm=jp2&w=1240&h=1540&fit=crop&auto=format,compress" alt="" />
          </div>
        </div>
        <div class="col-span-12 md:col-span-6 md:row-start-1 px-4">
          <div v-if="!isRecovering && !hasToken" class="mx-auto w-full max-w-sm lg:w-96 bg-white -mt-36 md:mt-0 p-6 md:border rounded-lg shadow-xl md:shadow-none">
            <div>
              <img class="h-8 w-auto" src="https://owayy.com/img/logos/OWAYY_logo_starrynight.svg" alt="Owayy logo" />
              <h2 class="mt-6 text-xl font-bold text-universeBlue">Sign in to your account</h2>
            </div>

            <div  class="mt-8">
              <div class="mt-6">
                <form class="space-y-6" @submit.prevent="login">
                  <div>
                    <label for="email" class="block text-sm font-medium text-gray-700"> Email address </label>
                    <div class="mt-1">
                      <input id="email" v-model="email" name="email" type="email" autocomplete="email" required="" class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-highlight-500 focus:border-highlight-500 sm:text-sm" />
                    </div>
                  </div>

                  <div class="space-y-1">
                    <label for="password" class="block text-sm font-medium text-gray-700"> Password </label>
                    <div class="mt-1">
                      <input id="password" v-model="password" name="password" type="password" autocomplete="current-password" required="" class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-highlight-500 focus:border-highlight-500 sm:text-sm" />
                    </div>
                  </div>

                  
                  <div class="flex items-center justify-end">
                    <!--<div class="hidden items-center">
                      <input id="remember-me" name="remember-me" type="checkbox" class="h-4 w-4 text-highlight-600 focus:ring-highlight-500 border-gray-300 rounded" />
                      <label for="remember-me" class="ml-2 block text-sm text-gray-900"> Remember me </label>
                    </div>-->

                    <div class="text-sm">
                      <a href="javascript:void(0)" class="font-medium text-highlight-600 hover:text-highlight-500 " @click="recoverPassword()"> Forgot your password? </a>
                    </div>
                  </div>
                  

                  <div>
                    <p
                      v-if="errorMsg"
                      class="text-red-500 text-sm mb-2">
                      {{ errorMsg }}
                    </p>
                    <button type="submit" class="relative w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-highlight-600 hover:bg-highlight-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-highlight-500">
                      <div v-if="loading" class="absolute inset-0 h-full w-full flex items-center justify-center">
                        <LoadingSpinner outer-class="text-white h-5 w-5" />
                      </div>
                      <span :class="loading ? 'invisible' : ''">
                        Sign in
                      </span>
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div v-else class="mx-auto w-full max-w-sm lg:w-96 bg-white -mt-36 md:mt-0 p-6 md:border rounded-lg shadow-xl md:shadow-none">
            <div>
              <img class="h-8 w-auto" src="https://owayy.com/img/logos/OWAYY_logo_starrynight.svg" alt="Owayy logo" />
              <h2 class="mt-6 text-xl font-bold text-universeBlue">Reset Password</h2>
            </div>

            <div  class="mt-8">
              <div v-if="!hasToken" class="mt-6">
                <form v-if="!sentRecovery" class="space-y-6" @submit.prevent="sendRecovery">
                  <div>
                    <label for="email" class="block text-sm font-medium text-gray-700"> Email address </label>
                    <div class="mt-1">
                      <input id="email" v-model="email" name="email" type="email" autocomplete="email" required="" class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-highlight-500 focus:border-highlight-500 sm:text-sm" />
                    </div>
                  </div>

                  <div>
                    <p
                      v-if="errorMsg"
                      class="text-red-500 text-sm mb-2">
                      {{ errorMsg }}
                    </p>
                    <button type="submit" class="relative w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-highlight-600 hover:bg-highlight-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-highlight-500">
                      <div v-if="loading" class="absolute inset-0 h-full w-full flex items-center justify-center">
                        <LoadingSpinner outer-class="text-white h-5 w-5" />
                      </div>
                      <span :class="loading ? 'invisible' : ''">
                        Reset Password
                      </span>
                    </button>
                  </div>
                </form>
                <div v-else>
                  <p
                    class="text-green-500 text-sm mb-2">
                    Please check your mail-box for instructions!
                  </p>
                </div>
              </div>
              <div v-if="hasToken" class="mt-6">
                <form  v-if="!recovered" class="space-y-6" @submit.prevent="changePassword">
                  <div>
                    <label for="password" class="block text-sm font-medium text-gray-700"> New password </label>
                    <div class="mt-1">
                      <input id="password" v-model="password" name="password" type="password"  required="" class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-highlight-500 focus:border-highlight-500 sm:text-sm" />
                    </div>
                  </div>
                  <div>
                    <label for="password2" class="block text-sm font-medium text-gray-700"> Repeat password </label>
                    <div class="mt-1">
                      <input id="password2" v-model="password2" name="password2" type="password"  required="" class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-highlight-500 focus:border-highlight-500 sm:text-sm" />
                    </div>
                  </div>
                  <div>
                    <p
                      v-if="errorMessage"
                      class="text-red-500 text-sm mb-2">
                      {{ errorMessage }}
                    </p>
                    <button type="submit" class="relative w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-highlight-600 hover:bg-highlight-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-highlight-500">
                      <div v-if="loading" class="absolute inset-0 h-full w-full flex items-center justify-center">
                        <LoadingSpinner outer-class="text-white h-5 w-5" />
                      </div>
                      <span :class="loading ? 'invisible' : ''">
                        Reset password
                      </span>
                    </button>
                  </div>
                </form>
                <div v-if="recovered">
                  <p
                    class="text-green-500 text-sm mb-2">
                    Your password has been changed.
                  </p>
                  <p>
                    <a href="javascript:void(0)" class="font-medium text-highlight-600 hover:text-highlight-500 " @click="restartLogin()"> Login Here</a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useMutation } from '@vue/apollo-composable'
import { useCookies } from 'vue3-cookies';
import { useRouter } from 'vue-router';
import { ref } from 'vue';
import { LOGIN } from "@/graphql/auth";
import LoadingSpinner from "@/components/UI/Indication/LoadingSpinner";

export default {
  name: 'Login',
  components: { LoadingSpinner },
  data() {
    return {
      isRecovering: false,
      sentRecovery: false ,
      recovered:false,
      hasToken: this.$route.query.token != null,
      token:this.$route.query.token,
      errorMessage:null
    };
  },
  methods:{
    
    async sendRecovery(){
      this.loading = true;
      const response = await fetch(`https://backoffice.owayy.com/api/recover/startrecover/${this.email}`)
      await response.json()
      this.sentRecovery = true;
    }, 
    
    async changePassword(){
      if(this.password !== this.password2){
        this.errorMessage = 'Passwords do not match. Please try again';
        return;
      }
      this.loading = true;
      const response = await fetch(`https://backoffice.owayy.com/api/recover/change/${this.token}/${this.password}`)
      await response.json()
      this.recovered = true;
    },

    restartLogin(){
      this.isRecovering= false;
      this.recovered= false;
      this.hasToken = false;
    },

    recoverPassword() {
      this.isRecovering = true;
    }    
  },
  setup() {
    const email = ref('');
    const password  = ref('');
    const password2  = ref('');
    const { cookies } = useCookies();
    const router = useRouter();
    const { mutate: login, loading, onDone, onError } = useMutation(LOGIN, () => (
      {
        variables: {
          data: {
            email: email.value,
            password: password.value
          }
        }
      }
    ))
  
    onDone(({ data }) => {
      const token = data.login.access_token;
      cookies.set('auth_session', token);
      router.push('/')
    })

    const errorMsg = ref('');

    onError(() => {
      errorMsg.value = 'Wrong credentials - please try again';
    })

    return {
      email,
      password,
      password2,
      login,
      loading,
      errorMsg
    }
  }
};
</script>
